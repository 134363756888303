.quote-container {
    text-align: center;
    justify-content: center;
}
.quote-text, .quote-sub-text{
    line-height: 1.3;
    word-spacing: 1px;
    position: center;
    font-style: italic;
}

.quote-text {
    font-size: 1.5em;
}

.quote-sub-text {
    font-size: 1.2em;
}