.image-section-container-row, .image-section-container-column {
    display: flex;
    width:100%;
    justify-content: space-between;
}

.image-section-container-row {
    flex-flow: row;
}

.image-section-container-column { 
    flex-flow: column;
}

.image-section-image-row, .image-section-image-column {
    padding: 5px;
    object-fit: cover;
}

.image-section-image-row {
    width: 60%;
}

.image-section-image-column {
    width: 100%;
}

.image-section-text-container {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.image-section-text, .imagesection-sub-text {
    line-height: 1.3;
    word-spacing: 1px;
    position: center;
}

.image-section-text {
    font-size: 1.5em;
}

.image-section-sub-text {
    font-size: 1.2em;
}