.header-style {
  position: center;
  text-align: center;
  font-size: 2em;
}

.content-container {
  margin-left: 8vw;
  margin-right: 8vw;
}

.content-style-bold, .content-style-center-bold {
  font-weight: bold;
  line-height: 1.3;
  word-spacing: 1px;
  position: center;
  font-size: 1.2em;
}

.content-style-bold {
  text-align: left;
}

.content-style-center-bold {
  text-align: center;
}

.content-divider {
  border-color: rgb(0 0 0 / 10%);
  margin: 1vw;
}

.list-container {
  text-align: center;
}

.list-style-center {
  display: inline-block;
  text-align: left;
}

.background {
  background-color: rgb(96 125 139 / 30%);
}

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  padding: 10px;
}

.row-item {
  flex-direction: column;
  display: flex;
}

.copyright-text {
  margin: 10px;
}