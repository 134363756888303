.hero-container {
    position: relative;
    text-align: center;
}

.hero-img {
    object-fit: cover;
    width:100%;
}

.text-container {
    max-width:100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.hero-text, .hero-text2, .hero-subtext {
    text-shadow: 2px 2px 3px rgb(0 0 0 / 60%);
    font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    color: white;
    text-align: center;
    margin: 5px;
    white-space: nowrap;
}

.hero-text {
    font-size: 10vw;
}

.hero-text2 {
    font-size: 6vw;
    white-space: pre-wrap;
}

.hero-subtext {
    font-size: 4vw;
}

.hero-divider {
    margin: 3vw;
}

.hero-buttons-container {
    white-space: nowrap;
    max-width:100%;
    align-content: center;
}